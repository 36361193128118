<template>
  <div class="TransferView">
    <van-nav-bar title="余额兑换" left-arrow @click-left="onClickLeft" />
    <div class="content_box text-center">
      <h2 class="van-title text-left">账户余额</h2>
      <van-cell-group>
        <van-field v-model="myMoney" disabled />
      </van-cell-group>
      <h2 class="van-title text-left">消费余额</h2>
      <van-cell-group>
        <van-field v-model="myMoney1" disabled />
      </van-cell-group>
      <h2 class="van-title text-left">兑换金额</h2>
      <van-cell-group>
        <van-field v-model="money" placeholder="请输入兑换金额" />
      </van-cell-group>
      <h2 class="van-title text-left">交易密码</h2>
      <van-cell-group>
        <van-field v-model="paypwd" type="password" placeholder="请输入交易密码" />
      </van-cell-group>
    </div>

    <div class="btn">
      <van-button
        color="linear-gradient(to right, rgb(222, 86, 7), rgb(228, 66, 66))"
        @click="submitHandler"
        block
      >立即提交</van-button>
    </div>
  </div>
</template>
<script>
import { exchange } from "@/api/users";

export default {
  name: "login",
  data() {
    return {
      myMoney: this.$store.getters.userinfo.money,
      myMoney1: this.$store.getters.userinfo.money1,
      money: "",
      paypwd: ""
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    submitHandler(e) {
      const { money, paypwd } = this;
      let field = { money, paypwd };

      exchange(field)
        .then(response => {
          this.$toast({
            message: response.msg,
            duration: 500,
            forbidClick: true
          });
          setTimeout(() => {
            this.$router.replace("/main/user");
          }, 500);
        })
        .catch(error => {});
    }
  }
};
</script>

<style lang="stylus" rel="stylesheet/stylus">
.TransferView
  .content_box
    background-color: #ffffff
    padding: 0.6rem 0.4rem
    margin: 0.3rem
    box-shadow: 0px 0px 0.1rem #dfdff9
    .van-title
      padding-top: 0.46rem
    h2
      color: $grayTextColor
    .van-cell-group
      border: 1px solid #f1ecec
      .van-cell
        padding: 0.15rem 0.3rem
  .btn
    padding: 0.2rem 0.3rem
</style>
